







































































































@import '@design';

.single-location--header {
  height: 400px;
  min-height: 50vh;
}

.hours--wrap {
  height: auto;
  color: white;
  background-color: $color-bluegiant-blue;
  .container {
    height: 100%;
  }

  @media (min-width: 960px) {
    height: 82px;
  }

  .hours-title--sm {
    font-size: 35px !important;
  }
}
